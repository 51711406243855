import React, { useContext } from 'react';
import { Link, graphql } from 'gatsby';
import { Breadcrumbs, Avatar } from '@material-ui/core';
import Interweave from 'interweave';
import Slider from 'react-slick';
// components
import { FirebaseContext } from '../components/Firebase';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Card from '../components/Card/Card';
import FormatDate from '../Functions/FormateDate';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SEO from '../components/Seo/seo';
import BlogComments from '../components/Comments/BlogComments';
import Carousel from '../components/Carousel/carousel';

const BlogTemplate = (props) => {
    const { firebase, user } = useContext(FirebaseContext);
    const { blogId, title } = props.pageContext;

    if (blogId && props.data) {
        const {
            title, content, author, allowComments, date, images, publish, id, tags, description, key,
        } = props.data.allBlogs.edges[0].node;

        //

        if (publish) {
            return (
                <GridContainer style={{ marginTop: 100, marginLeft: '2%', marginRight: '2%' }}>
                    <SEO
                        title={`My pale legs - ${title}`}
                        tags={tags}
                        description={description}
                    />
                    <Breadcrumbs>
                        <Link to="/">Home</Link>
                        <Link to="/blog">Blog Posts</Link>
                        <Link to={`/blog/posts/${id}`}>{title}</Link>
                    </Breadcrumbs>
                    <GridItem xs={12}>
                        <Card>
                            <GridContainer justify="center">
                                <h2 style={{ textAlign: 'center' }}>{title}</h2>
                            </GridContainer>
                            <GridContainer justify="center">
                                <GridItem xs={11}>
                                    <Carousel images={images} />
                                </GridItem>
                            </GridContainer>
                            <div style={{ padding: '5%' }}>
                                <Interweave
                                    content={content}
                                />
                                <GridContainer style={{ padding: 25 }} direction="row" justify="flex-start" alignItems="center">
                                    {author.photoURL !== ''
                                        ? (
                                            <Avatar
                                                alt="avatar"
                                                src={author.photoURL}
                                            />
                                        )
                                        : <Avatar><p>?</p></Avatar>}
                                    <div style={{ marginLeft: 10 }}>
                                        <p>
                                            Author:
                                            {author.displayName}
                                            <br />
                                            Written:
                                            {' '}
                                            {FormatDate(date)}
                                            {' '}
                                        </p>
                                    </div>
                                </GridContainer>
                            </div>
                            {allowComments
                                ? (
                                    <BlogComments
                                        blogId={key}
                                        firebase={firebase}
                                        user={user}
                                    />
                                ) : null}
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
        return (
            <GridContainer justify="center">
                <h3>{title}</h3>
                <h1>Oops, this blog is no longer available!</h1>
            </GridContainer>
        );
    }
    return (<div style={{ marginTop: 100, marginLeft: 10 }}><h3>{title}</h3></div>);
};
// todo - add keywords and description of each blog

export const query = graphql`
    query BlogPostQuery ($blogId:String!){
        allBlogs(filter: {id: {eq: $blogId}}) {
            edges {
                node {
                    id
                    key
                    title
                    images
                    publish
                    allowComments
                    content
                    date
                    tags
                    author {
                        displayName
                        photoURL
                    }
                }
            }
        }
    }
`;

export default BlogTemplate;
