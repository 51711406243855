import React, {useEffect, useReducer } from "react"
import {makeStyles} from "@material-ui/core/styles"
import {TextField, List, Avatar,ListItem,ListItemAvatar,ListItemText,Typography} from "@material-ui/core"


import { Loading } from "../Loading"
import BlogDivider from "../Dividers/Divider"

import GridContainer from "../Grid/GridContainer"
import Button from "../CustomButtons/Button"
import FormatDate from "../../Functions/FormateDate"
import Danger from "../Typography/Danger"


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  fonts: {
    fontWeight: "bold"
  },
  inline: {
    display: "inline"
  }
}));


const INITIAL_STATE={
  loading:false,
  comments:null,
  error:null,
  commentsErr:null,
  newComment:'',
  newCommentErr:'',
  notif:false,
  notifType:'warning',
  notifMsg:''
}

const reducer = (state,action)=>{
  const {type,payload}=action;
  switch(type){
    case 'notifClear':
      return {...state,notif:false,notifType: 'warning',notifMsg: ''}
    case 'newCommentError':
      return {...state,loading:false,notif:true,notifType: 'danger',notifMsg: payload}
    case 'newCommentSuccess':
      return {...state,loading:false,notif:true,notifType:'success',notifMsg:'Success! Your comment has been posted!',newComment: ''}
    case 'loading':
      return {...state,loading:!state.loading}
    case 'newComment':
      return {...state,newComment:payload}
    case 'loadComments':
      return {...state,comments:payload}
    case 'commentsErr':
      return {...state,error:payload}
    default:
      return {...state}
  }
}



const BlogComments=({blogId,firebase,user})=>{

  const classes=useStyles()


  const CommentComponent=({comment,id})=>{
    const {author,avatar,text, date,hidden}=comment;

    if(!hidden){
      return (
          <ListItem key={id} divider alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={"avatar"+author} src={avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography className={classes.fonts}>
                  {author}
                </Typography>
              }
              secondary={
                <>
                  {FormatDate(date)}
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {` - ${text}`}
                  </Typography>

                </>
              }
            />
          </ListItem>

      )
    }

    return null;


  }


  const [state,dispatch]=useReducer(reducer,INITIAL_STATE);
  const {loading,comments, error,newComment, newCommentErr}=state;


  useEffect(()=>{
    let subscriber

    if(firebase){

     subscriber =firebase.SubscribeToComments({postId:blogId,callback:snap=>{
         const values = snap.val();
         if(values){
           dispatch({type:"loadComments",payload:values})
         }
      },error:err=>dispatch({type:"commentsErr",payload:err.message})});
    }

    return ()=>{
        if(subscriber){
            firebase.UnSubScribeToComments(blogId).catch(err=>console.log(err.message));
        }
    }

  },[firebase])


  const handlePostComment=()=>{
    if(newComment.length<0 && !user){
      dispatch({type:'addCommentError',payload:'Please check your comment!'});
    }else{
      const {uid,displayName, photoURL}=user;
      dispatch({type:"loading"})
      firebase.postComment({
        author:displayName,
        authorId:uid,
        text:newComment,
        avatar:photoURL?photoURL:null,
        postId:blogId
      }).then(()=>dispatch({type:'newCommentSuccess'}))
        .catch((err)=>dispatch({type:"newCommentError",payload:err.message}))
    }

  }



  return (
    <div >
      <BlogDivider/>
      <Loading show={loading}/>
      <div style={{paddingLeft:25,paddingRight:25}}>
        <h4>Comments</h4>
        {user?
        <GridContainer>
          <TextField
            placeholder={'Your comment here...'}
            id={'newComment'}
            error={newCommentErr.length>0}
            helperText={newCommentErr}
            style={{margin:10}}
            value={newComment}
            size={'small'}
            fullWidth
            InputProps={{
              endAdornment:<Button onClick={handlePostComment} size={'sm'} color={'primary'} round>Post</Button>
            }}
            onChange={e=>dispatch({type:'newComment',payload:e.target.value})}
          />

        </GridContainer>:
          <h6>You need to be logged in to write a comment!</h6>
        }
        {comments?
          <List className={classes.root}>
            {Object.keys(comments).map(key=>{
              return <CommentComponent key={key} comment={comments[key]} id={key}/>
            })}
          </List>:
          <div>
            <p>No comments yet, be the first to have your say.</p>
          </div>
        }
        {error?
          <Danger>{error}</Danger>:null
        }

      </div>

    </div>
  )
}


export default BlogComments;