import React, { useEffect } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';
import useGetScreenWidth from '../../Hooks/useGetScreenWidth';

const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
};

const Carousel = ({ images }) => {
    const [screenWidth] = useGetScreenWidth();

    return screenWidth > 480 ? (
        <Slider
            {...settings}
        >
            {images.map((image, index) => (
                <div key={`${image}`}>
                    <div style={{
                        backgroundImage: `url("${image}")`,
                        maxHeight: 350,
                        // maxWidth:'100%',
                        height: 350,
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundSize: '150%',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        backgroundPosition: 'center bottom',
                        backgroundBlendMode: 'multiply',
                        backdropFilter: 'blur(15px)',
                        objectFit: 'cover',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <img
                            src={image}
                            alt={`${index}-image`}
                            // className="imageBlock"
                            style={{
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                minHeight: 350,
                                maxHeight: 350,
                                width: '100%',
                                objectFit: 'contain',
                                height: 'auto',
                                // minWidth:'100%'
                            }}
                        />
                    </div>
                </div>
            ))}
        </Slider>
    ) : (
        <Slider>
            {images.map((image, index) => (
                <div
                    key={image}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 10,
                        overflow: 'hidden',
                    }}
                >
                    <img
                        src={image}
                        alt={`${image}-${index}`}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            maxHeight: 350,
                            minHeight: 250,
                            width: '98%',
                            objectFit: 'contain',
                            height: 'auto',
                            borderRadius: 10,
                        }}
                    />
                </div>
            ))}
        </Slider>
    );
};

export default Carousel;
