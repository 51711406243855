import { useState, useEffect } from 'react';

export default () => {
    const [width, setWidth] = useState(480);
    useEffect(() => {
        // console.log('window width', window.innerWidth);
        setWidth(window.innerWidth);
    }, []);
    return [width];
};
